import React from 'react';
import '../css/footer.css'

class Footer extends React.Component {
    constructor(){
        super();
        this.state = {
            style: []
        }
    }

    componentDidMount = () => {
        window.addEventListener("resize", this.getHeightFooter)
    }

    getHeightFooter = () => {
        var elemento = document.getElementById('footer');
        let styleInput = window.getComputedStyle(elemento);
        let height = styleInput.getPropertyValue('height');
        document.getElementsByTagName("body")[0].style.marginBottom = height;
    }

    render() { 
        return (
            <>
                <div className="container-fluid footer" id='footer' onLoad={this.getHeightFooter}>
                    <footer className="pt-3">
                        <div className='row px-0 px-lg-5 py-3'>
                            <div className='col-12 col-lg-2 d-flex align-items-center justify-content-center justify-content-lg-start'>
                                <img src="/logo_gob.png" alt="logoFooter" width="auto" height="50"/>
                            </div>
                            <div className='col-2 d-none d-lg-flex align-items-center'>
                                <a href="https://gobierno.hidalgo.gob.mx/AvisoPrivacidad" target="_blank" rel='noreferrer' style={{ color: 'white', textDecoration: 'none' }}>Aviso de privacidad</a>
                            </div>
                            <div className='col-2 d-none d-lg-flex align-items-center justify-content-center'>
                                <div>
                                <p className='m-0'>911 Emergencia</p>
                                <p className='m-0'>069 Denuncia Anónima</p>
                                </div>
                            </div>
                            <div className='col-12 col-lg-6 d-flex align-items-center justify-content-center mt-3 mt-lg-0'>
                                <div>
                                <p className='m-0'>Contacto:</p>
                                <p className='m-0'>Blvd. Felipe Ángeles Núm. 605, Col. Venta Prieta. Pachuca de Soto, Hidalgo, México</p>
                                <p className='m-0'>(771) 715 99 59, (771) 715 99 60, (771) 715 99 61</p>
                                </div>
                            </div>
                            <div className='col-12 text-center'>
                                <a href="https://www.facebook.com/gobhidalgo" target="_blank" rel='noreferrer'><i className="bi bi-facebook text-white" style={{ fontSize: '20px' }}></i></a>
                                <a href="https://twitter.com/gobiernohidalgo" target="_blank" rel='noreferrer'><i className="bi bi-twitter-x text-white ms-4" style={{ fontSize: '20px' }}></i></a>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 pb-2 text-center'>
                                <hr style={{ color: '#BC955C', backgroundColor: '#BC955C', border: 'none', height: '2px', opacity: 1 }} className="mt-0"/>
                                <p className='mb-0'>Copyrights © 2024 Todos los derechos reservados por Hidalgo de la Transformación</p>
                            </div>
                        </div>
                    </footer>
                </div>
            </>
        );
    }
}
 
export default Footer;