import React, { Component } from 'react';

class AvisoPrivacidad extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() { 
        return ( 
            <>
            <div className="col-12 mt-2 text-center">
                <embed
                    src="/download/avisoPrivacidad.pdf"
                    type="application/pdf"
                    width="100%"
                    height="800px"
                />
                <label>
                    <input type="checkbox" checked={this.props.avisoLeido} onChange={this.props.onChangeAvisoLeido}/> He leído y estoy de acuerdo con el aviso de privacidad.
                </label>                
                <h6 className='text-center'>ESTE DOCUMENTO SERÁ FIRMADO EN LA FORMALIZACIÓN DEL APOYO</h6>
                <hr />
            </div>
            </>
         );
    }
}
 
export default AvisoPrivacidad;