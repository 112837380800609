import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import Routes from './components/Routes';
import { Toaster } from 'react-hot-toast';
import './css/style.css';
import Footer from './components/Footer';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';

function App() {

  const [showModal, setShowModal] = useState(false);

  return (
    <div>      
        <div>
          <nav className="navbar navbar-expand-lg bg-body-tertiary" style={{ backgroundColor: 'white', height: '80px' }}>
            <div className="container-fluid">
              <a className="navbar-brand" href="/">
                <img src="/hidalgo_gob_mx.png" alt="logoNavbar" width="auto" height="30"/>
              </a>
              <div className="collapse navbar-collapse" id="navbarText">
                <span className="navbar-text ms-auto" style={{ fontSize: '20px' }}>
                  INSTITUTO HIDALGUENSE DE COMPETITIVIDAD EMPRESARIAL
                </span>
              </div>
            </div>
          </nav>

          {/* Segunda fila con fondo rojo */}
          <div style={{ backgroundColor: '#B51C40', padding: '12px', color: 'white', textAlign: 'center', position: 'relative' }}>
            Emprendiendo Paso a Paso: Registro
            <button style={{ position: 'absolute', right: '10px', border: '1px solid white', borderRadius: '5px', color: 'white', backgroundColor: 'rgba(255,255,255,0.1)' }} onClick={() => setShowModal(true)}>Formatos de ejemplo</button>
          </div>
        </div>
        <Toaster
          toastOptions={{
            className: '',
            style: {
              border: '2px solid #333333',
            },
          }}
        />
        <Modal show={showModal} onHide={()=> setShowModal(false)} size="xl">
          <Modal.Header closeButton>

          </Modal.Header>
          <Modal.Body className='text-center'>
          <embed src="/ejemplos/resenaNegocioEjemplo.pdf" width="700" height="950" type="application/pdf"/>
          <embed src="/ejemplos/solicitudApoyoEjemplo.pdf" width="700" height="950" type="application/pdf"/>
          </Modal.Body>
        </Modal>
        <Router>
          <Routes/>
        </Router>
        <Footer/>
    </div>
  );
}

export default App;
