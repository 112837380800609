import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class Documentacion extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
        if (this.props.documentos.solicitudApoyo) {            
            document.getElementById('solicitudApoyo').files = this.props.documentos.solicitudApoyo;
        }
        if (this.props.documentos.identificacion) {            
            document.getElementById('identificacion').files = this.props.documentos.identificacion;
        }
        if (this.props.documentos.comprobanteDomicilio) {            
            document.getElementById('comprobanteDomicilio').files = this.props.documentos.comprobanteDomicilio;
        }
        if (this.props.documentos.resenaNegocio) {            
            document.getElementById('resenaNegocio').files = this.props.documentos.resenaNegocio;
        }
        if (this.props.documentos.estadoCuenta) {            
            document.getElementById('estadoCuenta').files = this.props.documentos.estadoCuenta;
        }
        if (this.props.documentos.curp) {            
            document.getElementById('curp').files = this.props.documentos.curp;
        }
    }

    onChangeFile = (event) => {
        const file = event.target.files[0];

        // Verificar si se seleccionó un archivo
        if (file) {
          // Verificar el tamaño del archivo
          const maxSize = 15 * 1024 * 1024; // 5 MB en bytes
          if (file.size > maxSize) {
            alert('El tamaño del archivo excede el límite permitido (15 MB).');
            event.target.value = null; // Limpia el valor del input
            return;
          }else{
            this.props.onChangeDocumento(event);
          }
        }else{
            this.props.onChangeDocumento(event);
        }
    }
    
    render() { 
        return ( 
            <>
                <h1 className='text-center' style={{ backgroundColor: '#ffe066', border: '3px solid red', borderRadius: '5px' }}>TODOS LOS FORMATOS DEBERÁN DE SER EN FORMATO PDF</h1>
                <hr />
                <div className="col-6 col-lg-3 col-xl-2 mt-3 mt-lg-3">
                    <label htmlFor="whatsappValidacion">Whatsapp</label>
                    <input type="tel" name="whatsappValidacion" className='form-control' pattern="^\d{10}$" maxLength={10} value={this.props.whatsappValidacion} onChange={this.props.onChangeValue}/>
                </div>
                <div className="col-12"></div>
                <div className="col-lg-7 mt-3 d-flex align-items-center">
                    <label htmlFor="" className='form-label m-0'>Identificación oficial por ambos lados en una sola página (INE vigente, pasaporte, cartilla militar y/o cédula profesional)</label>
                </div>
                <div className="col-lg-5 mt-3">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Subir archivo</Tooltip>}>
                        <input type="file" className={'form-control ' + (this.props.documentos.identificacion ? 'fileInputBorder' : '')} name='identificacion' id='identificacion' accept="application/pdf" onChange={this.onChangeFile}/>
                    </OverlayTrigger>
                </div>
                <div className="col-lg-7 mt-3 d-flex align-items-center">
                    <label htmlFor="" className='form-label m-0'>Comprobante de domicilio vigente (particular)</label>
                </div>
                <div className="col-lg-5 mt-3">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Subir archivo</Tooltip>}>
                        <input type="file" className={'form-control ' + (this.props.documentos.comprobanteDomicilio ? 'fileInputBorder' : '')} name='comprobanteDomicilio' id='comprobanteDomicilio' accept="application/pdf" onChange={this.onChangeFile}/>
                    </OverlayTrigger>
                </div>
                <div className="col-lg-7 mt-3 d-flex align-items-center">
                    <label htmlFor="" className='form-label m-0'>CURP</label>
                </div>
                <div className="col-lg-5 mt-3">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Subir archivo</Tooltip>}>
                        <input type="file" className={'form-control ' + (this.props.documentos.curp ? 'fileInputBorder' : '')} name='curp' id='curp' accept="application/pdf" onChange={this.onChangeFile}/>
                    </OverlayTrigger>
                </div>
                <div className="col-lg-7 mt-3 d-flex align-items-center">
                    <label htmlFor="" className='form-label m-0'>Carátula del estado de cuenta (que contenga el nombre del banco, número de cuenta, nombre de titular y clabe)</label>
                </div>
                <div className="col-lg-5 mt-3">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Subir archivo</Tooltip>}>
                        <input type="file" className={'form-control ' + (this.props.documentos.estadoCuenta ? 'fileInputBorder' : '')} name='estadoCuenta' id='estadoCuenta' accept="application/pdf" onChange={this.onChangeFile}/>
                    </OverlayTrigger>
                </div>
                <div className="col-lg-7 mt-3 d-flex align-items-center">
                    <label htmlFor="" className='form-label m-0'>Solicitud de apoyo (conservar después de cargar)</label>
                </div>
                <div className="col-lg-5 mt-3">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Subir archivo</Tooltip>}>
                        <input type="file" className={'form-control ' + (this.props.documentos.solicitudApoyo ? 'fileInputBorder' : '')} name='solicitudApoyo' id='solicitudApoyo' accept="application/pdf" onChange={this.onChangeFile}/>
                    </OverlayTrigger>
                </div>
                <div className="col-lg-7 mt-3 d-flex align-items-center">
                    <label htmlFor="" className='form-label m-0'>Reseña del negocio (conservar después de cargar)</label>
                </div>
                <div className="col-lg-5 mt-3">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Subir archivo</Tooltip>}>
                        <input type="file" className={'form-control ' + (this.props.documentos.resenaNegocio ? 'fileInputBorder' : '')} name='resenaNegocio' id='resenaNegocio' accept="application/pdf" onChange={this.onChangeFile}/>
                    </OverlayTrigger>
                </div>
            </>
         );
    }
}
 
export default Documentacion;