import React, { Component } from 'react';
import DatosGenerales from './DatosGenerales';
import Documentacion from './Documentacion';
import AvisoPrivacidad from './AvisoPrivacidad';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import validateCurp from 'validate-curp';
import { MoonLoader } from 'react-spinners';
import fondoSolicitudApoyo from '../img/solicitudApoyo.svg'
import fondoResenaNegocio from '../img/resenaNegocio.svg'
import '../css/solicitudApoyo.css';
import '../css/resenaNegocio.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

class Inicio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documentos: {
                solicitudApoyo: '',
                identificacion: '',
                comprobanteDomicilio: '',
                resenaNegocio: '',
                estadoCuenta: '',
                curp: ''

            },
            step: 1,
            camarasOrganismos: [],
            municipios: [],
            showModalFinalizar: false,
            datosSolicitante: {  
                tipoPersona: '',              
                rfc: '',
                nombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                nombreNegocio: '',
                noEmpleados: '',
                fechaInicioOperaciones: '',
                telefono: '',
                telefonoWhatsapp: '',
                curp: '',
                descripcionActividadEconomica: '',
                s_idCamaraOrganismo: '',
                correoElectronico: '',
                estadoCivil: '',
                grupoVulnerable: '',
                sexo: '',
                fechaNacimiento: '',

                ds_domicilio: '',
                ds_noInterior: '',
                ds_noExterior: '',
                ds_idMunicipio: '',
                ds_colonia: '',
                ds_localidad: '',
                ds_codigoPostal: '',
                dn_domicilio: '',
                dn_noInterior: '',
                dn_noExterior: '',
                dn_idMunicipio: '',
                dn_colonia: '',
                dn_localidad: '',
                dn_codigoPostal: '',

                destinoApoyo: '',
                telefonoWhatsappNegocio: '',
                situacionNegocio: '',
                productosServicios: '',
                recursos: '',
                noPersonas: '',
                beneficio: '',
                proyeccionVentas: '',
                proyeccionCostos: '',
                proyeccionGanancias: ''

            },
            avisoLeido: false,
            showModalCargando: false,
            showModalFormatos: false,
            fechaRegistro: '',
            idSolicitante: '',
            whatsappValidacion: ''
        }
    }

    componentDidMount = () => {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        this.selectCamarasOrganismos();
        this.selectMunicipios();
        this.getPermitirRegistros();
    }

    componentWillUnmount = () => {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload = (event) => {
        if (!this.state.showModalFinalizar) {
            if (this.state.datosSolicitante.nombre || this.state.datosSolicitante.apellidoPaterno || this.state.datosSolicitante.apellidoMaterno || this.state.datosSolicitante.rfc || this.state.datosSolicitante.nombreNegocio || this.state.datosSolicitante.noEmpleados || this.state.datosSolicitante.fechaInicioOperaciones 
                || this.state.datosSolicitante.telefono || this.state.datosSolicitante.telefonoWhatsapp || this.state.datosSolicitante.curp || this.state.datosSolicitante.grupoVulnerable 
                || this.state.datosSolicitante.descripcionActividadEconomica || this.state.datosSolicitante.s_idCamaraOrganismo || this.state.datosSolicitante.correoElectronico
                || this.state.datosSolicitante.estadoCivil) {            
                    const message = "¿Estás seguro de abandonar la página? Se perderán los datos ingresados.";
                    event.returnValue = message; // Standard for most browsers
                    return message; // For some older browsers
                }
        }
    };

    getPermitirRegistros = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'ultimoID' })
        };
        const response = await fetch('https://www.encargo2.bpmservicio.com.mx/php/DB/solicitantes.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            if (!data.permitir) {
                document.getElementById('form').style.pointerEvents = 'none';
                alert("Lo sentimos, hemos llegado al limite de registros para este programa.\n350 TURNOS.");
            }
        }else{
            console.log('Error al revisar comprobar número de registros.');
        }
    }

    selectCamarasOrganismos = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
        };
        const response = await fetch('https://www.encargo2.bpmservicio.com.mx/php/DB/camarasorganismos.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                camarasOrganismos: data.camarasOrganismos
            })
        }else{
            console.log('Error al obtener cámaras y organismos.');
        }
    }
    
    selectMunicipios = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'select' })
        };
        const response = await fetch('https://www.encargo2.bpmservicio.com.mx/php/DB/municipios.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                municipios: data.municipios
            })
        }else{
            console.log('Error al obtener municipios.');
        }
    }

    submit = async (e) => {
        e.preventDefault();
        if (this.state.avisoLeido) {
            this.setState({
                showModalCargando: true
            })
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    operacion: 'insert',
                    tipoPersona: this.state.datosSolicitante.tipoPersona,
                    rfc: this.state.datosSolicitante.rfc,
                    nombre: this.state.datosSolicitante.nombre,
                    apellidoPaterno: this.state.datosSolicitante.apellidoPaterno,
                    apellidoMaterno: this.state.datosSolicitante.apellidoMaterno,
                    nombreNegocio: this.state.datosSolicitante.nombreNegocio,
                    noEmpleados: this.state.datosSolicitante.noEmpleados,
                    fechaInicioOperaciones: this.state.datosSolicitante.fechaInicioOperaciones,
                    telefono: this.state.datosSolicitante.telefono,
                    telefonoWhatsapp: this.state.datosSolicitante.telefonoWhatsapp,
                    curp: this.state.datosSolicitante.curp,
                    descripcionActividadEconomica: this.state.datosSolicitante.descripcionActividadEconomica,
                    s_idCamaraOrganismo: this.state.datosSolicitante.s_idCamaraOrganismo,
                    correoElectronico: this.state.datosSolicitante.correoElectronico,
                    estadoCivil: this.state.datosSolicitante.estadoCivil,
                    grupoVulnerable: this.state.datosSolicitante.grupoVulnerable,
                    sexo: this.state.datosSolicitante.sexo,
                    fechaNacimiento: this.state.datosSolicitante.fechaNacimiento,

                    ds_domicilio: this.state.datosSolicitante.ds_domicilio,
                    ds_noInterior: this.state.datosSolicitante.ds_noInterior,
                    ds_noExterior: this.state.datosSolicitante.ds_noExterior,
                    ds_idMunicipio: this.state.datosSolicitante.ds_idMunicipio,
                    ds_colonia: this.state.datosSolicitante.ds_colonia,
                    ds_localidad: this.state.datosSolicitante.ds_localidad,
                    ds_codigoPostal: this.state.datosSolicitante.ds_codigoPostal,
                    dn_domicilio: this.state.datosSolicitante.dn_domicilio,
                    dn_noInterior: this.state.datosSolicitante.dn_noInterior,
                    dn_noExterior: this.state.datosSolicitante.dn_noExterior,
                    dn_idMunicipio: this.state.datosSolicitante.dn_idMunicipio,
                    dn_colonia: this.state.datosSolicitante.dn_colonia,
                    dn_localidad: this.state.datosSolicitante.dn_localidad,
                    dn_codigoPostal: this.state.datosSolicitante.dn_codigoPostal,

                    destinoApoyo: this.state.datosSolicitante.destinoApoyo,
                    telefonoWhatsappNegocio: this.state.datosSolicitante.telefonoWhatsappNegocio,
                    situacionNegocio: this.state.datosSolicitante.situacionNegocio,
                    productosServicios: this.state.datosSolicitante.productosServicios,
                    recursos: this.state.datosSolicitante.recursos,
                    noPersonas: this.state.datosSolicitante.noPersonas,
                    beneficio: this.state.datosSolicitante.beneficio,
                    proyeccionVentas: this.state.datosSolicitante.proyeccionVentas,
                    proyeccionCostos: this.state.datosSolicitante.proyeccionCostos,
                    proyeccionGanancias: Number(this.state.datosSolicitante.proyeccionVentas) - Number(this.state.datosSolicitante.proyeccionCostos)
                })
            };
            const response = await fetch('https://www.encargo2.bpmservicio.com.mx/php/DB/solicitantes.php', requestOptions);
            const data = await response.json();
            if (data.error === 0) {  
                let idSolicitante = data.idSolicitante;
                const formData = new FormData();
                formData.append('idSolicitante', idSolicitante);
                formData.append('solicitudApoyo', this.state.documentos.solicitudApoyo[0]);
                formData.append('identificacion', this.state.documentos.identificacion[0]);
                formData.append('comprobanteDomicilio', this.state.documentos.comprobanteDomicilio[0]);
                formData.append('resenaNegocio', this.state.documentos.resenaNegocio[0]);
                formData.append('estadoCuenta', this.state.documentos.estadoCuenta[0]);
                formData.append('curp', this.state.documentos.curp[0]);
                for (var pair of formData.entries()) {
                    console.log(pair[0]+ ', ' + pair[1]); 
                }
                var xhr = new XMLHttpRequest();
                xhr.open('POST','https://www.encargo2.bpmservicio.com.mx/php/DB/documentos.php', true);
                xhr.onload = async function () {
                    var dataImg = JSON.parse(xhr.responseText);
                    if (dataImg.error === 0) {
                        this.setState({
                            showModalCargando: false,
                            showModalFinalizar: true,
                            fechaRegistro: data.fechaRegistro,
                            idSolicitante: data.idSolicitante
                        })
                    }else{
                        console.log('Error al subir documentos.');
                        this.setState({
                            showModalCargando: false
                        })
                    }
                }.bind(this);
                xhr.send(formData); 
            }else{
                if (data.mensaje === 'SQLSTATE[45000]: <<Unknown error>>: 1644 Se ha alcanzado el límite máximo de 350 registros.') {
                    this.setState({
                        showModalCargando: false
                    })
                    alert("Lo sentimos, hemos llegado al limite de registros para este programa.\n350 TURNOS.");
                    this.recargar();
                }else{
                    console.log(data.mensaje);
                    toast.error('Error al registrar beneficiario.');
                    this.setState({
                        showModalCargando: false
                    })
                }
            }
        }else{
            alert('Para eso, acepte el aviso de privacidad.');
        }
    }

    continuar = () => {
        if (this.state.step === 1) {
            this.validarStep1();
        }
        if (this.state.step === 2) {
            this.validarStep2();
        }
    }

    retroceder = () => {
        this.setState((prevState) => ({
            step: prevState.step - 1
        }))
    }

    recargar = () => {
        window.location.reload();
    }

    onChangeValue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    
    onChangeValueSolicitante = (e) => {
        this.setState({
            ...this.state,
            datosSolicitante: {
                ...this.state.datosSolicitante,
                [e.target.name]: e.target.value
            }
        })
    }
    
    onChangeMontoSolicitante = (campo, monto) => {
        this.setState({
            ...this.state,
            datosSolicitante: {
                ...this.state.datosSolicitante,
                [campo]: monto
            }
        })
    }
    
    onChangeValueGrupoVulnerable = (e) => {
        this.setState({
            ...this.state,
            datosSolicitante: {
                ...this.state.datosSolicitante,
                [e.target.name]: e.target.value,
            }
        })
    }

    onChangeValueSituacionNegocio = (e) => {
        if (e.target.value === 'idea') {
            this.setState({
                ...this.state,
                datosSolicitante: {
                    ...this.state.datosSolicitante,
                    [e.target.name]: e.target.value,
                    nombreNegocio: '',
                    noEmpleados: '',
                    descripcionActividadEconomica: '',
                    telefono: '',
                    telefonoWhatsappNegocio: '',
                    rfc: '',
                    s_idCamaraOrganismo: '',
                    correoElectronico: '',
                    tipoPersona: 'No Aplica'
                }
            })
        }else{
            this.setState({
                ...this.state,
                datosSolicitante: {
                    ...this.state.datosSolicitante,
                    [e.target.name]: e.target.value,
                }
            })
        }
    }

    onChangeValueSolicitanteNumbersOnly = (e) => {
        const inputValue = e.target.value;

        // Filtrar solo números
        const numericValue = inputValue.replace(/\D/g, '');

        this.setState({
            ...this.state,
            datosSolicitante: {
                ...this.state.datosSolicitante,
                [e.target.name]: numericValue
            }
        })
    }

    onChangeAvisoLeido = () => {
        this.setState({
            avisoLeido: !this.state.avisoLeido
        })
    }

    onChangeDocumento = (e) => {
        if (e.target.files[0]) {
            this.setState({
                ...this.state,
                documentos: {
                    ...this.state.documentos,
                    [e.target.name]: e.target.files
                }
            })
        }else{
            this.setState({
                ...this.state,
                documentos: {
                    ...this.state.documentos,
                    [e.target.name]: ''
                }
            })
        }
    }

    validarDatosNegocio = async() => {
        let valido = false;
        if (this.state.datosSolicitante.situacionNegocio === 'idea') {
            valido = true;
        }else{
            if (this.state.datosSolicitante.nombreNegocio && this.state.datosSolicitante.noEmpleados && this.state.datosSolicitante.descripcionActividadEconomica 
                && this.state.datosSolicitante.telefono && this.state.datosSolicitante.telefonoWhatsappNegocio && this.state.datosSolicitante.rfc
                && this.state.datosSolicitante.s_idCamaraOrganismo && this.state.datosSolicitante.correoElectronico) {                  
                if (this.state.datosSolicitante.rfc.length > 5 && this.state.datosSolicitante.rfc.length < 14) {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ operacion: 'rfcExiste', rfc: this.state.datosSolicitante.rfc })
                    };
                    const response = await fetch('https://www.encargo2.bpmservicio.com.mx/php/DB/solicitantes.php', requestOptions);
                    const data = await response.json();
                    if (data.error === 0) {
                        if (!data.existe) { 
                            if (this.state.datosSolicitante.telefono.length === 10 && Number(this.state.datosSolicitante.telefono > 0)) {                    
                                if (this.state.datosSolicitante.telefonoWhatsappNegocio.length === 10 && Number(this.state.datosSolicitante.telefonoWhatsappNegocio > 0)) {
                                    if (Number(this.state.datosSolicitante.dn_codigoPostal) > 0) {                              
                                        if (this.validarCorreo(this.state.datosSolicitante.correoElectronico)) {
                                            valido = true;
                                        }else{
                                            toast('Correo electrónico inválido.', {
                                                icon: <i className="bi bi-exclamation-circle-fill"></i>,
                                            });
                                        }  
                                    }else{
                                        toast('Codigo postal del negocio inválido.', {
                                            icon: <i className="bi bi-exclamation-circle-fill"></i>,
                                        });
                                    }                   
                                }else{
                                    toast('Número telefónico de Whatsapp del negocio inválido.', {
                                        icon: <i className="bi bi-exclamation-circle-fill"></i>,
                                    });                                        
                                }                   
                            }else{
                                toast('Número telefónico inválido.', {
                                    icon: <i className="bi bi-exclamation-circle-fill"></i>,
                                });
                            }
                        }else{
                            toast('Este RFC ya se encuentra registrado.', {
                                icon: <i className="bi bi-exclamation-circle-fill"></i>,
                            });
                        }
                    }else{
                        toast('Error al validar RFC.', {
                            icon: <i className="bi bi-exclamation-circle-fill"></i>,
                        });
                    }
                }else{
                    toast('RFC inválido.', {
                        icon: <i className="bi bi-exclamation-circle-fill"></i>,
                    });
                }
            }else{                
                toast('Llene los campos antes de continuar.', {
                    icon: <i className="bi bi-exclamation-circle-fill"></i>,
                });
            }
        }
        return valido
    }

    validarStep1 = async () => {
        if (this.state.datosSolicitante.nombre && this.state.datosSolicitante.apellidoPaterno && this.state.datosSolicitante.apellidoMaterno && this.state.datosSolicitante.ds_domicilio 
            && this.state.datosSolicitante.ds_colonia && this.state.datosSolicitante.ds_localidad && this.state.datosSolicitante.ds_idMunicipio && this.state.datosSolicitante.ds_codigoPostal 
            && this.state.datosSolicitante.telefonoWhatsapp && this.state.datosSolicitante.estadoCivil && this.state.datosSolicitante.curp && this.state.datosSolicitante.grupoVulnerable 
            && this.state.datosSolicitante.sexo && this.state.datosSolicitante.fechaNacimiento && this.state.datosSolicitante.destinoApoyo && this.state.datosSolicitante.situacionNegocio 
            && this.state.datosSolicitante.productosServicios && this.state.datosSolicitante.recursos && this.state.datosSolicitante.noPersonas && this.state.datosSolicitante.tipoPersona 
            && this.state.datosSolicitante.proyeccionVentas && this.state.datosSolicitante.proyeccionCostos && this.state.datosSolicitante.beneficio && this.state.datosSolicitante.dn_domicilio 
            && this.state.datosSolicitante.dn_colonia && this.state.datosSolicitante.dn_localidad && this.state.datosSolicitante.dn_idMunicipio && this.state.datosSolicitante.dn_codigoPostal) {
                if (this.state.datosSolicitante.telefonoWhatsapp.length === 10 && Number(this.state.datosSolicitante.telefonoWhatsapp > 0)) {
                    if (validateCurp(this.state.datosSolicitante.curp)) {
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ operacion: 'curpExiste', curp: this.state.datosSolicitante.curp })
                        };
                        const response = await fetch('https://www.encargo2.bpmservicio.com.mx/php/DB/solicitantes.php', requestOptions);
                        const data = await response.json();
                        if (data.error === 0) {
                            if (!data.existe) {
                                if (Number(this.state.datosSolicitante.ds_codigoPostal) > 0) {
                                    if (Number(this.state.datosSolicitante.dn_codigoPostal) > 0) {
                                        if (Number(this.state.datosSolicitante.proyeccionVentas) > 0) {
                                            if (Number(this.state.datosSolicitante.proyeccionCostos) > 0) {
                                                if (await this.validarDatosNegocio()) {                                                                    
                                                    this.showModalFormatos();
                                                }
                                            }else{
                                                toast('La proyección de costos debe ser mayor a 0.', {
                                                    icon: <i className="bi bi-exclamation-circle-fill"></i>,
                                                });
                                            }
                                        }else{
                                            toast('La proyección de ventas debe ser mayor a 0.', {
                                                icon: <i className="bi bi-exclamation-circle-fill"></i>,
                                            });
                                        }
                                    }else{
                                        toast('Codigo postal del negocio inválido.', {
                                            icon: <i className="bi bi-exclamation-circle-fill"></i>,
                                        });
                                    }
                                }else{
                                    toast('Codigo postal del solicitante inválido.', {
                                        icon: <i className="bi bi-exclamation-circle-fill"></i>,
                                    });
                                }
                            }else{
                                toast('Esta CURP ya se encuentra registrada.', {
                                    icon: <i className="bi bi-exclamation-circle-fill"></i>,
                                });
                            }
                        }else{
                            toast('Error al validar CURP.', {
                                icon: <i className="bi bi-exclamation-circle-fill"></i>,
                            });
                        }
                    }else{
                        toast('CURP inválida.', {
                            icon: <i className="bi bi-exclamation-circle-fill"></i>,
                        });
                    }
                }else{
                    toast('Número telefónico de Whatsapp inválido.', {
                        icon: <i className="bi bi-exclamation-circle-fill"></i>,
                    });
                }
        }else{
            toast('Llene los campos antes de continuar.', {
                icon: <i className="bi bi-exclamation-circle-fill"></i>,
            });
        }
    }

    validarStep2 = () => {
        if (this.state.datosSolicitante.telefonoWhatsapp === this.state.whatsappValidacion) {
            if (this.state.documentos.identificacion[0] && this.state.documentos.solicitudApoyo[0] && this.state.documentos.comprobanteDomicilio[0] && this.state.documentos.resenaNegocio[0] && this.state.documentos.estadoCuenta[0] && this.state.documentos.curp[0]) {
                    this.setState((prevState) => ({
                        step: prevState.step + 1
                    }))
            }else{
                toast('Debe seleccionar los documentos correspondientes.', {
                    icon: <i className="bi bi-exclamation-circle-fill"></i>,
                });
            }            
        }else{
            toast('El número de Whatsapp no coincide con el ingresado anteriormente.', {
                icon: <i className="bi bi-exclamation-circle-fill"></i>,
            });
        }
    }

    validarCorreo = (email) => {
        let valid = false;

      // Expresión regular para validar el correo electrónico
      var regexCorreo = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (regexCorreo.test(email)) {
        valid = true;
      }
      return valid
    }

    showModalFormatos = () => {
        this.setState({
            showModalFormatos: true
        })
    }

    hideModalFormatos = () => {
        this.setState({
            showModalFormatos: false
        })
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === 0) {
            return '0.00';
        } else {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    handleDownloadPDFSolicitud = () => {
        const input = document.querySelector('.solicitudApoyo');
    
        // Aumenta la escala para mejorar la calidad
        html2canvas(input, { scale: 1.8 }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'in', 'letter'); // Configuración para tamaño carta en pulgadas
    
          // Las medidas de la página
          const pageWidth = 8.5;
          const pageHeight = 11;
    
          // Las medidas de la imagen en píxeles
          const imgWidth = canvas.width;
          const imgHeight = canvas.height;
    
          // Escalar la imagen para ajustarse al tamaño de la página
          let widthRatio = pageWidth * 96 / imgWidth;
          let heightRatio = pageHeight * 96 / imgHeight;
          let scale = Math.min(widthRatio, heightRatio);
    
          let scaledWidth = imgWidth * scale / 96;
          let scaledHeight = imgHeight * scale / 96;
    
          pdf.addImage(imgData, 'PNG', 0, 0, scaledWidth, scaledHeight);
          pdf.save('solicitudApoyo.pdf');
        });
    };
    
    handleDownloadPDFResena = () => {
    const input = document.querySelector('.resenaNegocio');

    // Aumenta la escala para mejorar la calidad
    html2canvas(input, { scale: 1.8 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'in', 'letter'); // Configuración para tamaño carta en pulgadas

        // Las medidas de la página
        const pageWidth = 8.5;
        const pageHeight = 11;

        // Las medidas de la imagen en píxeles
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        // Escalar la imagen para ajustarse al tamaño de la página
        let widthRatio = pageWidth * 96 / imgWidth;
        let heightRatio = pageHeight * 96 / imgHeight;
        let scale = Math.min(widthRatio, heightRatio);

        let scaledWidth = imgWidth * scale / 96;
        let scaledHeight = imgHeight * scale / 96;

        pdf.addImage(imgData, 'PNG', 0, 0, scaledWidth, scaledHeight);
        pdf.save('resenaNegocio.pdf');
    });
    };
    
    handleDownloadPDFTicket = () => {
    const input = document.querySelector('.ticketTurno');

    // Aumenta la escala para mejorar la calidad
    html2canvas(input, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('l', 'in', [5, 3]); // Configuración para tamaño carta en pulgadas

        // Las medidas de la página
        const pageWidth = 5;
        const pageHeight = 3;

        // Las medidas de la imagen en píxeles
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        // Escalar la imagen para ajustarse al tamaño de la página
        let widthRatio = pageWidth * 96 / imgWidth;
        let heightRatio = pageHeight * 96 / imgHeight;
        let scale = Math.min(widthRatio, heightRatio);

        let scaledWidth = imgWidth * scale / 96;
        let scaledHeight = imgHeight * scale / 96;

        pdf.addImage(imgData, 'PNG', 0, 0, scaledWidth, scaledHeight);
        pdf.save('turno.pdf');
        this.recargar();
    });
    };

    render() { 
        return ( 
            <div className="container contenido pb-5">
                <div className="d-none d-lg-block">
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '50px' }}>
                        <div className="circulo-container">
                            <div className={"circulo " + (this.state.step > 1 ? 'circuloLleno' : '')}>{this.state.step > 1 ? <i className="bi bi-check-lg"></i> : <p className='m-0'>1</p>}</div>
                            <p className={"texto-personalizado " + (this.state.step === 1 ? 'texto-personalizadoActive' : '')}>Datos Generales</p>
                        </div>
                        <div className="circulo-container">
                            <div className={"circulo " + (this.state.step > 2 ? 'circuloLleno' : '')}>{this.state.step > 2 ? <i className="bi bi-check-lg"></i> : <p className='m-0'>2</p>}</div>
                            <p className={"texto-personalizado " + (this.state.step === 2 ? 'texto-personalizadoActive' : '')}>Documentación</p>
                        </div>
                        <div className="circulo-container">
                            <div className={"circulo " + (this.state.step > 3 ? 'circuloLleno' : '')}>{this.state.step > 3 ? <i className="bi bi-check-lg"></i> : <p className='m-0'>3</p>}</div>
                            <p className={"texto-personalizado " + (this.state.step === 3 ? 'texto-personalizadoActive' : '')}>Aviso de privacidad</p>
                        </div>
                    </div>
                </div>
                <div className="d-lg-none">
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '50px' }}>
                        <div className="circulo-container">
                            <div className={"circulo " + (this.state.step > 1 ? 'circuloLleno' : '')}>{this.state.step > 1 ? <i className="bi bi-check-lg"></i> : <p className='m-0'>1</p>}</div>
                            <p className={"texto-personalizado " + (this.state.step === 1 ? 'texto-personalizadoActive' : '')} style={{ fontSize: '12px' }}>Datos Generales</p>
                        </div>
                    </div>
                </div>
                <div className="d-lg-none">
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <div className="circulo-container">
                            <div className={"circulo " + (this.state.step > 2 ? 'circuloLleno' : '')}>{this.state.step > 2 ? <i className="bi bi-check-lg"></i> : <p className='m-0'>2</p>}</div>
                            <p className={"texto-personalizado " + (this.state.step === 2 ? 'texto-personalizadoActive' : '')} style={{ fontSize: '12px' }}>Documentación</p>
                        </div>
                        <div className="circulo-container">
                            <div className={"circulo " + (this.state.step > 3 ? 'circuloLleno' : '')}>{this.state.step > 3 ? <i className="bi bi-check-lg"></i> : <p className='m-0'>3</p>}</div>
                            <p className={"texto-personalizado " + (this.state.step === 3 ? 'texto-personalizadoActive' : '')} style={{ fontSize: '12px' }}>Aviso de privacidad</p>
                        </div>
                    </div>
                </div>
                <div className="card mt-4 pb-2 px-2" style={{ border: 'none', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
                    <div className="card-body">
                        <form onSubmit={this.submit} id="form">
                            <div className="row">
                                {this.state.step === 1 && <DatosGenerales camarasOrganismos={this.state.camarasOrganismos} datosSolicitante={this.state.datosSolicitante} municipios={this.state.municipios} onChangeValueSolicitante={this.onChangeValueSolicitante} onChangeValueGrupoVulnerable={this.onChangeValueGrupoVulnerable} onChangeMontoSolicitante={this.onChangeMontoSolicitante} onChangeValueSolicitanteNumbersOnly={this.onChangeValueSolicitanteNumbersOnly} onChangeValueSituacionNegocio={this.onChangeValueSituacionNegocio}/>}
                                {this.state.step === 2 && <Documentacion documentos={this.state.documentos} datosSolicitante={this.state.datosSolicitante} onChangeDocumento={this.onChangeDocumento} onChangeValue={this.onChangeValue}/>}
                                {this.state.step === 3 && <AvisoPrivacidad finalizar={this.finalizar} avisoLeido={this.state.avisoLeido} onChangeAvisoLeido={this.onChangeAvisoLeido}/>}
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    {this.state.step === 2 && <button className='btnContinuarRegresar' type='button' onClick={this.retroceder}>Regresar</button>}
                                    {this.state.step === 3 && <button className='btnContinuarRegresar' type='button' onClick={this.retroceder}>Regresar</button>}
                                </div>
                                <div className="col-6 text-end">
                                    {this.state.step === 1 && <button className='btnContinuarRegresar' type='button' onClick={this.continuar}>Continuar</button>}
                                    {this.state.step === 2 && <button className='btnContinuarRegresar' type='button' onClick={this.continuar}>Continuar</button>}
                                    {this.state.step === 3 && <button className='btnContinuarRegresar' type='submit'>Finalizar</button>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Modal show={this.state.showModalFinalizar} onHide={this.recargar} centered size='lg'>
                    <Modal.Header className='d-block text-center'>                        
                        <h4 className='m-0'>Registro realizado exitosamente</h4>
                        <h5 className='m-0 mt-2'>Conserve sus documentos y este turno en caso de ser seleccionado.</h5>
                    </Modal.Header>
                    <Modal.Body className='d-flex justify-content-center'>
                        <div className='ticketTurno' style={{ width: '5in', height: '3in', border: '2px solid' }}>
                            <div className="container-fluid">
                                <img src="/logosTurno.jpg" alt="cabecera turno" width="100%"/>
                            </div>
                            <div style={{ backgroundColor: '#B51C40', padding: '12px', color: 'white', textAlign: 'center' }}>
                                Emprendiendo Paso a Paso
                            </div>
                            <p className='text-center mt-3' style={{ fontSize: '20px', fontWeight: '500' }}>Turno {this.state.idSolicitante}</p>
                            <p className='text-center' style={{ fontSize: '20px', fontWeight: '500' }}>{this.state.datosSolicitante.curp}</p>
                            <p className='text-center'>{new Date(this.state.fechaRegistro).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}</p>
                            <p className='text-center'>{new Date(this.state.fechaRegistro).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='d-flex justify-content-center'>
                        <button className='btnContinuarRegresar' onClick={this.handleDownloadPDFTicket}>Descargar Turno</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showModalCargando} centered>
                    <Modal.Header className='d-flex justify-content-center'>
                        Espere un momento, estamos cargando sus documentos...
                    </Modal.Header>
                    <Modal.Body className='d-flex justify-content-center'>
                        <div className='text-start'>
                            <MoonLoader color="#36d7b7" />
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showModalFormatos} onHide={this.hideModalFormatos} centered size='xl'>
                    <Modal.Header style={{ fontWeight: '600', fontSize: '18px' }}>
                        Observe los siguientes formatos y corrobore que su información sea correcta. De ser asi, descárguelos, fírmelos y escanéelos. Mientras hace esto, no cierre esta pagina, ya que tendrá que subirlos en el siguiente paso.
                    </Modal.Header>
                    <Modal.Body className=''>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                                <div style={{ border: '2px solid black' }}>
                                    <div className='solicitudApoyo'>
                                        <img src={fondoSolicitudApoyo} alt="" className='imgFondo'/>
                                        <div className='nombreSolicitante'>{`${this.state.datosSolicitante.nombre} ${this.state.datosSolicitante.apellidoPaterno} ${this.state.datosSolicitante.apellidoMaterno}`}</div>
                                        <div className='domicilioSolicitante'>{`${this.state.datosSolicitante.ds_domicilio} ${this.state.datosSolicitante.ds_noExterior} ${this.state.datosSolicitante.ds_noInterior && `INT. ${this.state.datosSolicitante.ds_noInterior}`}`}</div>
                                        <div className='coloniaSolicitante'>{this.state.datosSolicitante.ds_colonia}</div>
                                        <div className='localidadSolicitante'>{this.state.datosSolicitante.ds_localidad}</div>
                                        <div className='municipioSolicitante'>{(this.state.datosSolicitante.ds_idMunicipio && this.state.municipios.find(e => Number(e.idMunicipio) === Number(this.state.datosSolicitante.ds_idMunicipio))) && this.state.municipios.find(e => Number(e.idMunicipio) === Number(this.state.datosSolicitante.ds_idMunicipio)).nombre}</div>
                                        <div className='estadoSolicitante'>Hidalgo</div>
                                        <div className='codigoPostalSolicitante'>{this.state.datosSolicitante.ds_codigoPostal}</div>
                                        <div className='whatsappSolicitante'>{this.state.datosSolicitante.telefonoWhatsapp}</div>
                                        <div className='estadoCivilSolicitante'>{this.state.datosSolicitante.estadoCivil === 'unionLibre' ? 'Unión Libre' : this.state.datosSolicitante.estadoCivil}</div>
                                        <div className='curpSolicitante'>{this.state.datosSolicitante.curp}</div>
                                        <div className='vulnerableSi'>{this.state.datosSolicitante.grupoVulnerable !== 'Ninguno' && 'X'}</div>
                                        <div className='vulnerableNo'>{this.state.datosSolicitante.grupoVulnerable === 'Ninguno' && 'X'}</div>
                                        <div className='grupoVulnerable'>{this.state.datosSolicitante.grupoVulnerable !== 'Ninguno' && this.state.datosSolicitante.grupoVulnerable}</div>
                                        <div className='destinoApoyo'>{this.state.datosSolicitante.destinoApoyo}</div>
                                        <div className='nombreNegocio'>{this.state.datosSolicitante.situacionNegocio === 'idea' ? 'SIN DATOS' : this.state.datosSolicitante.nombreNegocio}</div>
                                        <div className='noEmpleados'>{this.state.datosSolicitante.situacionNegocio === 'idea' ? 'SIN DATOS' : this.state.datosSolicitante.noEmpleados}</div>
                                        <div className='descripcionActividadEconomica'>{this.state.datosSolicitante.situacionNegocio === 'idea' ? 'SIN DATOS' : this.state.datosSolicitante.descripcionActividadEconomica}</div>
                                        <div className='fechaInicioOperaciones'>{this.state.datosSolicitante.fechaInicioOperaciones === '' ? 'SIN DATOS' : new Date(this.state.datosSolicitante.fechaInicioOperaciones + ' 00:00:00').toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}</div>
                                        <div className='domicilioNegocio'>{`${this.state.datosSolicitante.dn_domicilio} ${this.state.datosSolicitante.dn_noExterior} ${this.state.datosSolicitante.dn_noInterior && `INT. ${this.state.datosSolicitante.dn_noInterior}`}`}</div>
                                        <div className='coloniaNegocio'>{this.state.datosSolicitante.dn_colonia}</div>
                                        <div className='localidadNegocio'>{this.state.datosSolicitante.dn_localidad}</div>
                                        <div className='municipioNegocio'>{(this.state.datosSolicitante.dn_idMunicipio && this.state.municipios.find(e => Number(e.idMunicipio) === Number(this.state.datosSolicitante.dn_idMunicipio))) && this.state.municipios.find(e => Number(e.idMunicipio) === Number(this.state.datosSolicitante.dn_idMunicipio)).nombre}</div>
                                        <div className='estadoNegocio'>{'Hidalgo'}</div>
                                        <div className='codigoPostalNegocio'>{this.state.datosSolicitante.dn_codigoPostal}</div>
                                        <div className='telefonoNegocio'>{this.state.datosSolicitante.situacionNegocio === 'idea' ? 'SIN DATOS' : this.state.datosSolicitante.telefono}</div>
                                        <div className='whatsappNegocio'>{this.state.datosSolicitante.situacionNegocio === 'idea' ? 'SIN DATOS' : this.state.datosSolicitante.telefonoWhatsappNegocio}</div>
                                        <div className='rfc'>{this.state.datosSolicitante.situacionNegocio === 'idea' ? 'SIN DATOS' : this.state.datosSolicitante.rfc}</div>
                                        <div className='camaraOrganismo'>{this.state.datosSolicitante.situacionNegocio === 'idea' ? 'SIN DATOS' : (this.state.datosSolicitante.s_idCamaraOrganismo && this.state.camarasOrganismos.find(e => Number(e.idCamaraOrganismo) === Number(this.state.datosSolicitante.s_idCamaraOrganismo))) && this.state.camarasOrganismos.find(e => Number(e.idCamaraOrganismo) === Number(this.state.datosSolicitante.s_idCamaraOrganismo)).nombre}</div>
                                        <div className='email'>{this.state.datosSolicitante.situacionNegocio === 'idea' ? 'SIN DATOS' : this.state.datosSolicitante.correoElectronico}</div>
                                        <div className='dia'>{new Date().getDate()}</div>
                                        <div className='mes'>{new Intl.DateTimeFormat('es-ES', { month: 'long' }).format(new Date())}</div>
                                        <div className='nombreSolicitanteFirma'>{`${this.state.datosSolicitante.nombre} ${this.state.datosSolicitante.apellidoPaterno} ${this.state.datosSolicitante.apellidoMaterno}`}</div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center mt-4">
                                <div style={{ border: '2px solid black' }}>
                                    <div className='resenaNegocio'>
                                        <img src={fondoResenaNegocio} alt="" className='imgFondo'/>
                                        <div className='fecha'>{new Date().toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}</div>
                                        <div className="personaFisica">{this.state.datosSolicitante.tipoPersona === 'Fisica' && 'X'}</div>
                                        <div className="personaMoral">{this.state.datosSolicitante.tipoPersona === 'Moral' && 'X'}</div>
                                        <div className="personaNA">{this.state.datosSolicitante.tipoPersona === 'No Aplica' && 'X'}</div>
                                        <div className='nombreSolicitante'>{`${this.state.datosSolicitante.nombre} ${this.state.datosSolicitante.apellidoPaterno} ${this.state.datosSolicitante.apellidoMaterno}`}</div>
                                        <div className='nombreNegocio'>{this.state.datosSolicitante.nombreNegocio === '' ? 'SIN DATOS' : this.state.datosSolicitante.nombreNegocio}</div>
                                        <div className="situacionNegocioOperaciones">{this.state.datosSolicitante.situacionNegocio === 'operaciones' && 'X'}</div>
                                        <div className="situacionNegocioIdea">{this.state.datosSolicitante.situacionNegocio === 'idea' && 'X'}</div>
                                        <div className='localidadNegocio'>{`${this.state.datosSolicitante.dn_localidad}, ${(this.state.datosSolicitante.dn_idMunicipio && this.state.municipios.find(e => Number(e.idMunicipio) === Number(this.state.datosSolicitante.dn_idMunicipio))) && this.state.municipios.find(e => Number(e.idMunicipio) === Number(this.state.datosSolicitante.dn_idMunicipio)).nombre}, HIDALGO`}</div>
                                        <div className="productosServicios">{this.state.datosSolicitante.productosServicios}</div>
                                        <div className="recursos">{this.state.datosSolicitante.recursos}</div>
                                        <div className="noPersonas">{this.state.datosSolicitante.noPersonas}</div>
                                        <div className='destinoApoyo'>{this.state.datosSolicitante.destinoApoyo}</div>
                                        <div className='proyeccionVentas'>{this.numberWithCommas(this.state.datosSolicitante.proyeccionVentas)}</div>
                                        <div className='proyeccionCostos'>{this.numberWithCommas(this.state.datosSolicitante.proyeccionCostos)}</div>
                                        <div className='proyeccionGanancias'>{this.numberWithCommas(Number(this.state.datosSolicitante.proyeccionVentas) - Number(this.state.datosSolicitante.proyeccionCostos))}</div>
                                        <div className='beneficio'>{this.state.datosSolicitante.beneficio}</div>
                                        <div className='nombreSolicitanteFirma'>{`${this.state.datosSolicitante.nombre} ${this.state.datosSolicitante.apellidoPaterno} ${this.state.datosSolicitante.apellidoMaterno}`}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btnContinuarRegresar' onClick={() => {this.hideModalFormatos()}}>Cancelar</button>
                        <button className='btnContinuarRegresar' onClick={() => {this.handleDownloadPDFResena(); this.handleDownloadPDFSolicitud();}}>Descargar Formatos</button>
                        <button className='btnContinuarRegresar' type='button' onClick={() => {if(window.confirm('Recuerde descargar los formatos, imprimirlos, llenarlos y escanearlos. Conservar estos documentos en caso de ser seleccionado.')){this.setState((prevState) => ({step: prevState.step + 1, showModalFormatos: false}))}}}>Continuar</button>
                    </Modal.Footer>
                </Modal>
            </div>
         );
    }
}
 
export default Inicio;