import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';

class DatosGenerales extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
        // Obtener la fecha actual en el formato yyyy-mm-dd
        function obtenerFechaActual() {
            const hoy = new Date();
            const mes = hoy.getMonth() + 1; // Se suma 1 porque los meses van de 0 a 11
            const dia = hoy.getDate();
            const año = hoy.getFullYear();

            const fechaActual = `${año}-${mes < 10 ? '0' : ''}${mes}-${dia < 10 ? '0' : ''}${dia}`;
            return fechaActual;
        }

        // Asignar la fecha actual al atributo max del input
        document.getElementById('fechaInicioOperaciones').max = obtenerFechaActual();
    }

    render() { 
        return ( 
            <>
            <h5>Datos del solicitante</h5>
            <hr />
            <div className="col-12 col-md-6 col-lg-4 col-xl-4 mt-3 mt-lg-2">
                <label htmlFor="nombre">Nombre(s)</label>
                    <input type="text" name='nombre' className='form-control' value={this.props.datosSolicitante.nombre} onChange={this.props.onChangeValueSolicitante}/>
            </div>
            <div className="col-12 col-md-6 col-lg-3 col-xl-4 mt-3 mt-lg-2">
                <label htmlFor="apellidoPaterno">Apellido paterno</label>
                    <input type="text" name='apellidoPaterno' className='form-control' value={this.props.datosSolicitante.apellidoPaterno} onChange={this.props.onChangeValueSolicitante}/>
            </div>
            <div className="col-12 col-md-6 col-lg-3 col-xl-4 mt-3 mt-lg-3 mt-xl-2">
                <label htmlFor="apellidoMaterno">Apellido materno</label>
                    <input type="text" name='apellidoMaterno' className='form-control' value={this.props.datosSolicitante.apellidoMaterno} onChange={this.props.onChangeValueSolicitante}/>
            </div>
            <div className="col-12 col-lg-4 mt-3">
                <label htmlFor="ds_domicilio">Domicilio</label>
                <input type="text" name="ds_domicilio" className='form-control' value={this.props.datosSolicitante.ds_domicilio} onChange={this.props.onChangeValueSolicitante}/>
            </div>
            <div className="col-12 col-lg-2 mt-3">
                <label htmlFor="ds_noExterior">Número Exterior</label>
                <input type="text" name="ds_noExterior" className='form-control' value={this.props.datosSolicitante.ds_noExterior} onChange={this.props.onChangeValueSolicitante}/>
            </div>
            <div className="col-12 col-lg-2 mt-3">
                <label htmlFor="ds_noInterior">Número Interior</label>
                <input type="text" name="ds_noInterior" className='form-control' value={this.props.datosSolicitante.ds_noInterior} onChange={this.props.onChangeValueSolicitante}/>
            </div>
            <div className="col-6 col-lg-4 mt-3">
                <label htmlFor="ds_colonia">Colonia</label>
                    <input type="text" name="ds_colonia" className='form-control' value={this.props.datosSolicitante.ds_colonia} onChange={this.props.onChangeValueSolicitante}/>
            </div>
            <div className="col-6 col-lg-4 mt-3">
                    <label htmlFor="ds_localidad">Localidad</label>
                        <input type="text" name="ds_localidad" className='form-control' value={this.props.datosSolicitante.ds_localidad} onChange={this.props.onChangeValueSolicitante}/>
            </div>
            <div className="col-6 col-lg-4 mt-3">
                <label htmlFor="ds_idMunicipio">Municipio</label>
                    <select name="ds_idMunicipio" className='form-control' value={this.props.datosSolicitante.ds_idMunicipio} onChange={this.props.onChangeValueSolicitante}>
                        <option value="">Seleccionar</option>
                        {
                            this.props.municipios.map((e, index) =>
                                <option value={e.idMunicipio} key={index}>{e.nombre}</option>                            
                            )
                        }
                    </select>
            </div>
            <div className="col-6 col-lg-2 mt-3">
                <label htmlFor="ds_codigoPostal">Código postal</label>
                    <input type="text" name='ds_codigoPostal' className='form-control' maxLength={5} value={this.props.datosSolicitante.ds_codigoPostal} onChange={this.props.onChangeValueSolicitanteNumbersOnly}/>
            </div>
            <div className="col-6 col-lg-3 col-xl-2 mt-3 mt-lg-3">
                <label htmlFor="telefonoWhatsapp">Whatsapp</label>
                 <input type="tel" name="telefonoWhatsapp" className='form-control' pattern="^\d{10}$" maxLength={10} value={this.props.datosSolicitante.telefonoWhatsapp} onChange={this.props.onChangeValueSolicitante}/>
            </div>
            <div className="col-6 col-lg-3 col-xl-2 mt-3">
                <label htmlFor="estadoCivil">Estado civil</label>
                    <select name="estadoCivil" className='form-control' value={this.props.datosSolicitante.estadoCivil} onChange={this.props.onChangeValueSolicitante}>
                        <option value="">Seleccionar</option>
                        <option value="casado/a">Casado/a</option>
                        <option value="divorciado/a">Divorciado/a</option>
                        <option value="soltero/a">Soltero/a</option>
                        <option value="unionLibre">Unión libre</option>
                        <option value="viudo/a">Viudo/a</option>
                    </select>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mt-3">
                <label htmlFor="curp">CURP</label>
                    <input type="text" name='curp' className='form-control' maxLength={18} value={this.props.datosSolicitante.curp} onChange={this.props.onChangeValueSolicitante}/>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mt-3">
                <label htmlFor="grupoVulnerable">Grupo Vulnerable</label>
                <select name="grupoVulnerable" className='form-control' value={this.props.datosSolicitante.grupoVulnerable} onChange={this.props.onChangeValueGrupoVulnerable}>
                    <option value="">Seleccionar</option>
                    <option value="Ninguno">Ninguno</option>
                    <option value="Personas con discapacidad">Personas con discapacidad</option>
                    <option value="Migrantes, refugiados y solicitantes de asilo">Migrantes, refugiados y solicitantes de asilo</option>
                    <option value="Personas Mayores">Personas Mayores</option>
                    <option value="Étnica">Étnica</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-4 mt-3">
                <label htmlFor="sexo">Sexo (Únicamente para datos estadísticos)</label>
                <select name="sexo" className='form-control' value={this.props.datosSolicitante.sexo} onChange={this.props.onChangeValueSolicitante}>
                    <option value="">Seleccionar</option>
                    <option value="F">Femenino</option>
                    <option value="M">Masculino</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-2 mt-3">
                <label htmlFor="fechaNacimiento">Fecha de nacimiento</label>
                    <input type="date" name="fechaNacimiento" className='form-control' id='fechaNacimiento' value={this.props.datosSolicitante.fechaNacimiento} onChange={this.props.onChangeValueSolicitante}/>
            </div>





            <h5 className='mt-5'>Características del apoyo</h5>
            <hr />
            <div className="col-12 mt-2">
                <div style={{ position: 'relative' }}>
                    <label htmlFor="destinoApoyo">Especificar destino del apoyo</label>
                    <textarea name="destinoApoyo" cols="30" rows="2" maxLength={200} className='form-control' value={this.props.datosSolicitante.destinoApoyo} onChange={this.props.onChangeValueSolicitante}></textarea>
                    <p className='contadorCaracteres m-0' style={{ position: 'absolute', right: 0, color: 'gray' }}>{200 - this.props.datosSolicitante.destinoApoyo.length}/200</p>                
                </div>
            </div>





            <h5 className='mt-5'>Datos Generales del Negocio (si aún no esta operando, ingresar estimados)</h5>
            <hr />
            <div className="col-12 col-lg-4 mt-2">
                <label htmlFor="situacionNegocio">Situación actual del negocio</label>
                <select name="situacionNegocio" className='form-control' value={this.props.datosSolicitante.situacionNegocio} onChange={this.props.onChangeValueSituacionNegocio}>
                    <option value="">Seleccionar</option>
                    <option value="operaciones">En Operaciones</option>
                    <option value="idea">Es una idea</option>
                </select>
            </div>
            <div className="col-12 col-lg-4 col-xl-6 mt-2">
                <label htmlFor="nombreNegocio">Nombre Comercial</label>
                    <input type="text" name='nombreNegocio' className='form-control' value={this.props.datosSolicitante.nombreNegocio} onChange={this.props.onChangeValueSolicitante} placeholder={this.props.datosSolicitante.situacionNegocio === 'idea' ? "SIN DATOS" : ""} disabled={this.props.datosSolicitante.situacionNegocio === 'idea'}/>
            </div>
            <div className="col-12 col-md-6 col-lg-3 col-xl-2 mt-2">
                <label htmlFor="noEmpleados">Número de empleados</label>
                    <select name="noEmpleados" className='form-control' value={this.props.datosSolicitante.noEmpleados} onChange={this.props.onChangeValueSolicitante} disabled={this.props.datosSolicitante.situacionNegocio === 'idea'}>
                        <option value="">Seleccionar</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
            </div>
            <div className="col-12 mt-3">                     
                <div style={{ position: 'relative' }}>
                    <label htmlFor="descripcionActividadEconomica">Descripción de actividad económica</label>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Panadería, confección de ropa, venta de comida, farmacia, elaboración de productos de belleza, etc.</Tooltip>}>
                        <textarea name="descripcionActividadEconomica" cols="30" rows="1" maxLength={60} className='form-control' value={this.props.datosSolicitante.descripcionActividadEconomica} onChange={this.props.onChangeValueSolicitante} placeholder={this.props.datosSolicitante.situacionNegocio === 'idea' ? "SIN DATOS" : ""} disabled={this.props.datosSolicitante.situacionNegocio === 'idea'}></textarea>
                    </OverlayTrigger>
                    <p className='contadorCaracteres m-0' style={{ position: 'absolute', right: 0, color: 'gray' }}>{60 - this.props.datosSolicitante.descripcionActividadEconomica.length}/60</p>
                </div>
            </div>
            <div className="col-12 mt-3">             
                <div style={{ position: 'relative' }}>
                    <label htmlFor="productosServicios">Describa los productos y/o servicios que ofrece</label>
                        <textarea name="productosServicios" cols="30" rows="2" maxLength={200} className='form-control' value={this.props.datosSolicitante.productosServicios} onChange={this.props.onChangeValueSolicitante}></textarea>
                        <p className='contadorCaracteres m-0' style={{ position: 'absolute', right: 0, color: 'gray' }}>{200 - this.props.datosSolicitante.productosServicios.length}/200</p>
                </div>
            </div>
            <div className="col-12 mt-3">                
                <div style={{ position: 'relative' }}>
                    <label htmlFor="recursos">¿Con qué recursos cuenta (dinero, equipo, lugar, otro o ninguno)? Especifica</label>
                        <textarea name="recursos" cols="30" rows="2" maxLength={200} className='form-control' value={this.props.datosSolicitante.recursos} onChange={this.props.onChangeValueSolicitante}></textarea>
                        <p className='contadorCaracteres m-0' style={{ position: 'absolute', right: 0, color: 'gray' }}>{200 - this.props.datosSolicitante.recursos.length}/200</p>
                </div>
            </div>
            <div className="col-12 mt-3">
                <div style={{ position: 'relative' }}>
                    <label htmlFor="noPersonas">¿Cuántas personas participarán en este proyecto?</label>
                        <textarea name='noPersonas' cols="30" rows="2" maxLength={200} className='form-control' value={this.props.datosSolicitante.noPersonas} onChange={this.props.onChangeValueSolicitante}></textarea>
                        <p className='contadorCaracteres m-0' style={{ position: 'absolute', right: 0, color: 'gray' }}>{200 - this.props.datosSolicitante.noPersonas.length}/200</p>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mt-3">
                <label htmlFor="fechaInicioOperaciones">Fecha de inicio de operaciones</label>
                    <input type="date" name="fechaInicioOperaciones" className='form-control' id='fechaInicioOperaciones' value={this.props.datosSolicitante.fechaInicioOperaciones} onChange={this.props.onChangeValueSolicitante}/>
            </div>
            <div className="col-12 col-lg-4 mt-3">
                <label htmlFor="dn_domicilio">Domicilio del negocio</label>
                <input type="text" name="dn_domicilio" className='form-control' value={this.props.datosSolicitante.dn_domicilio} onChange={this.props.onChangeValueSolicitante}/>
            </div>
            <div className="col-12 col-lg-2 mt-3">
                <label htmlFor="ds_noExterior">Número Exterior</label>
                <input type="text" name="ds_noExterior" className='form-control' value={this.props.datosSolicitante.ds_noExterior} onChange={this.props.onChangeValueSolicitante}/>
            </div>
            <div className="col-12 col-lg-2 mt-3">
                <label htmlFor="ds_noInterior">Número Interior</label>
                <input type="text" name="ds_noInterior" className='form-control' value={this.props.datosSolicitante.ds_noInterior} onChange={this.props.onChangeValueSolicitante}/>
            </div>
            <div className="col-6 col-lg-3 mt-3">
                <label htmlFor="dn_colonia">Colonia</label>
                    <input type="text" name="dn_colonia" className='form-control' value={this.props.datosSolicitante.dn_colonia} onChange={this.props.onChangeValueSolicitante}/>
            </div>
            <div className="col-6 col-lg-3 mt-3">
                    <label htmlFor="dn_localidad">Localidad</label>
                        <input type="text" name="dn_localidad" className='form-control' value={this.props.datosSolicitante.dn_localidad} onChange={this.props.onChangeValueSolicitante}/>
            </div>
            <div className="col-6 col-lg-4 mt-3">
                <label htmlFor="dn_idMunicipio">Municipio</label>
                    <select name="dn_idMunicipio" className='form-control' value={this.props.datosSolicitante.dn_idMunicipio} onChange={this.props.onChangeValueSolicitante}>
                        <option value="">Seleccionar</option>
                        {
                            this.props.municipios.map((e, index) =>
                                <option value={e.idMunicipio} key={index}>{e.nombre}</option>                            
                            )
                        }
                    </select>
            </div>
            <div className="col-6 col-lg-2 mt-3">
                <label htmlFor="dn_codigoPostal">Código postal</label>
                    <input type="text" name='dn_codigoPostal' className='form-control' maxLength={5} value={this.props.datosSolicitante.dn_codigoPostal} onChange={this.props.onChangeValueSolicitanteNumbersOnly}/>
            </div>
            <div className={"col-6 col-lg-3 col-xl-3 mt-3"}>
                <label htmlFor="telefono">Teléfono del negocio</label>
                    <input type="tel" name="telefono" className='form-control' pattern="^\d{10}$" maxLength={10} value={this.props.datosSolicitante.telefono} onChange={this.props.onChangeValueSolicitante} placeholder={this.props.datosSolicitante.situacionNegocio === 'idea' ? "SIN DATOS" : ""} disabled={this.props.datosSolicitante.situacionNegocio === 'idea'}/>
            </div>
            <div className="col-6 col-lg-3 col-xl-3 mt-3 mt-lg-3">
                <label htmlFor="telefonoWhatsappNegocio">Whatsapp</label>
                 <input type="tel" name="telefonoWhatsappNegocio" className='form-control' pattern="^\d{10}$" maxLength={10} value={this.props.datosSolicitante.telefonoWhatsappNegocio} onChange={this.props.onChangeValueSolicitante} placeholder={this.props.datosSolicitante.situacionNegocio === 'idea' ? "SIN DATOS" : ""} disabled={this.props.datosSolicitante.situacionNegocio === 'idea'}/>
            </div>
            <div className="col-5 col-lg-2 mt-3">
                <label htmlFor="tipoPersona">Tipo de Persona</label>
                <select name="tipoPersona" className='form-control' value={this.props.datosSolicitante.tipoPersona} onChange={this.props.onChangeValueSolicitante} disabled={this.props.datosSolicitante.situacionNegocio === 'idea'}>
                    <option value="">Seleccionar</option>
                    <option value="Fisica">Fisica</option>
                    <option value="Moral">Moral</option>
                    <option value="No Aplica">No Aplica</option>
                </select>
            </div>
            <div className="col-7 col-lg-4 mt-3">
                <label htmlFor="rfc">RFC</label>
                    <input type="text" name='rfc' className='form-control' maxLength={(this.props.datosSolicitante.tipoPersona === 'Moral' ? 12 : 13)} value={this.props.datosSolicitante.rfc} onChange={this.props.onChangeValueSolicitante} placeholder={this.props.datosSolicitante.situacionNegocio === 'idea' ? "SIN DATOS" : ""} disabled={this.props.datosSolicitante.situacionNegocio === 'idea'}/>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mt-3">
                <label htmlFor="s_idCamaraOrganismo">Cámara u organismo empresarial</label>
                    <select name="s_idCamaraOrganismo" className='form-control' value={this.props.datosSolicitante.s_idCamaraOrganismo} onChange={this.props.onChangeValueSolicitante} placeholder={this.props.datosSolicitante.situacionNegocio === 'idea' ? "SIN DATOS" : ""} disabled={this.props.datosSolicitante.situacionNegocio === 'idea'}>
                        <option value="">Seleccionar</option>
                        {
                            this.props.camarasOrganismos.map((e, index) =>
                                <option value={e.idCamaraOrganismo} key={index}>{e.nombre}</option>                            
                            )
                        }                             
                    </select>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mt-3">
                <label htmlFor="correoElectronico">Correo electrónico</label>
                    <input type="email" name="correoElectronico" className='form-control' value={this.props.datosSolicitante.correoElectronico} onChange={this.props.onChangeValueSolicitante} placeholder={this.props.datosSolicitante.situacionNegocio === 'idea' ? "SIN DATOS" : ""} disabled={this.props.datosSolicitante.situacionNegocio === 'idea'}/>
            </div>





            <h5 className='mt-5'>Flujos esperados</h5>
            <hr />
            <div className="col-6 col-lg-4 mt-2">
                <label htmlFor="proyeccionVentas">Ventas mensuales proyectadas</label>
                    <div className="input-group">
                        <span className='input-group-text'>$</span>
                        <NumericFormat thousandSeparator=',' className='form-control text-end' required value={this.props.datosSolicitante.proyeccionVentas} defaultValue={''} placeholder='0.0' onValueChange={(values) => this.props.onChangeMontoSolicitante('proyeccionVentas', Number(values.value))}/>
                    </div>
            </div>
            <div className="col-6 col-lg-4 mt-2">
                <label htmlFor="proyeccionCostos">Costos mensuales proyectados</label>
                    <div className="input-group">
                        <span className='input-group-text'>$</span>
                        <NumericFormat thousandSeparator=',' className='form-control text-end' required value={this.props.datosSolicitante.proyeccionCostos} defaultValue={''} placeholder='0.0' onValueChange={(values) => this.props.onChangeMontoSolicitante('proyeccionCostos', Number(values.value))}/>
                    </div>
            </div>
            <div className="col-6 col-lg-4 mt-2">
                <label htmlFor="proyeccionCostos">Ganancia mensuales proyectadas</label>
                    <div className="input-group">
                        <span className='input-group-text'>$</span>
                        <NumericFormat thousandSeparator=',' className='form-control text-end' required value={this.props.datosSolicitante.proyeccionVentas - this.props.datosSolicitante.proyeccionCostos} defaultValue={''} placeholder='0.0' disabled/>
                    </div>
            </div>




            <h5 className='mt-5'>¿Cuál es el beneficio que espera lograr con el apoyo?</h5>
            <div className="col-12 mt-3 mb-3">                
                <div style={{ position: 'relative' }}>
                    <textarea name="beneficio" cols="30" rows="2" maxLength={200} className='form-control' value={this.props.datosSolicitante.beneficio} onChange={this.props.onChangeValueSolicitante}></textarea>
                    <p className='contadorCaracteres m-0' style={{ position: 'absolute', right: 0, color: 'gray' }}>{200 - this.props.datosSolicitante.beneficio.length}/200</p>
                </div>
            </div>
            </>
         );
    }
}
 
export default DatosGenerales;